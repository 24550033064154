.main__chatbody {
  max-width: 24%;
  min-width: 22%;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #fff;
  border: #989CA3 2px solid;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
