/* body {
    margin: 0;
    padding: 0;
  } */
  .component {
    width: 100%;
    text-align: center;
  }
  .slider--init {
    user-select: none;
    touch-action: pan-y;
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    cursor: grab;
    cursor: -webkit-grab;
    overflow: visible;
  }
  .slider--init--down {
    cursor: grabbing;
    cursor: -webkit-grabbing;
  }
  .slider--init > :first-child {
    transform: translate3d(0px, 0px, 0px);
  }
  .slider--init--bounce > :first-child {
    transition: transform 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  .scroller-container {
    width: 3000px;
    position: relative;
    /* background-color: #ccc; */
    height: 300px;
    overflow: visible;
  }
  .scroller-node {
    width: 450px;
    height: 100%;
    /* background-color: #999; */
    display: inline-block;
    /* text-align: center; */
    /* font-size: 64px; */
    /* color: #fff; */
    /* font-weight: bold;
    line-height: 300px; */
    margin-right: 20px;
  }
  .scroller-node:nth-child(even) {
    /* background-color: #888; */
  }
  .scroller-node:nth-child(3n) {
    /* background-color: #777; */
  }
  